import { StoreonModule } from 'storeon';
import { Events, State, ItemPosition } from '..';
import { widgetExpandedMiddleware, widgetCurrentUrlMiddleware } from '../middlewares/widgetMiddleware';
import { getPositionFromOffer } from '../../dealer';

export type WidgetState = {
  widget: {
    expanded: boolean;
    rendered: boolean;
    position?: ItemPosition;
    currentUrl?: string;
  };
};

export type WidgetEvents = {
  'widget/expanded': boolean;
  'widget/rendered': boolean;
  'widget/position': ItemPosition;
  'widget/onDragEnd': ItemPosition;
  'widget/currentUrl': string;

  '@events/widget/expanded': boolean;
  '@events/widget/iframeSrcSet': undefined;
  '@events/widget/iframeLoaded': undefined;
};

export const widgetModule: StoreonModule<State, Events> = ({ on, dispatch }) => {
  on('@init', () => ({
    widget: {
      expanded: false,
      rendered: false,
    },
  }));

  on('widget/expanded', (state, expanded) => {
    widgetExpandedMiddleware({ dispatch, state, expanded });
    return {
      widget: {
        ...state.widget,
        expanded,
      },
      // write to data capsule only if expanded value has changed
      ...(state.widget.expanded !== expanded && {
        __dataCapsule: Date.now(),
      }),
    };
  });

  on('widget/rendered', ({ widget }, rendered) => ({
    widget: {
      ...widget,
      rendered,
    },
  }));

  on('widget/onDragEnd', (oldState, position) => ({
    widget: {
      ...oldState.widget,
      position,
    },
    __dataCapsule: Date.now(),
  }));

  on('chatbot/setDealerOffer', (state, dealerOffer) => {
    if (state.initParams.isInvokedFromHeader) {
      return state;
    }
    const position = getPositionFromOffer(dealerOffer);
    if (!position) {
      return state;
    }
    return {
      widget: {
        ...state.widget,
        position,
      },
    };
  });

  on('widget/currentUrl', (state, currentUrl) => {
    widgetCurrentUrlMiddleware({ currentUrl, state });
    return {
      widget: {
        ...state.widget,
        currentUrl,
      },
    };
  });

  on('app/setInitialData', (state, { widgetSession }) => {
    // do not restore widget state if it's "from-the-header"(it can be saved if user opens landing page with chatbot widget)
    if (!widgetSession?.widgetData || state.initParams.isInvokedFromHeader) {
      return;
    }
    const { widget } = widgetSession.widgetData;
    const {
      widget: { expanded, position },
    } = state;
    return {
      widget: {
        ...state.widget,
        expanded: widget?.expanded ?? expanded,
        position: widget?.position ?? position,
      },
    };
  });
};
