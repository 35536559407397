import { getWidgetPositionByAnchorSelector } from '../../utils/position';
import { StoreonDispatch } from 'storeon';
import { Events, State } from '../index';
import { openChatbotPage } from '../../utils/openChatbotPage';
import { CHATBOT_PAGE_URL } from '../../constants';
import { wixSupportChatSDKEmitUpdateWidget } from '../../components/common';
import { publishWidgetExpanded } from '../../events/publish/widgetChannel';
import { markAsRead } from '../../api/messageApi';

type WidgetExpandedMiddleware = {
  dispatch: StoreonDispatch<Events>;
  state: State;
  expanded: boolean;
};

/**
 * @param dispatch
 * @param state: the current state before changing it in the reducer
 * @param expanded: the payload of expand action - indicate the new state
 * Invoked when dispatch(widget/expanded).
 */
export const widgetExpandedMiddleware = ({ dispatch, state, expanded }: WidgetExpandedMiddleware) => {
  const { isInvokedFromHeader, anchorSelector, origin } = state.initParams;

  // update position only when we open the widget (from the header)
  if (isInvokedFromHeader && anchorSelector && expanded && !state.widget.expanded) {
    const widgetPosition = getWidgetPositionByAnchorSelector(anchorSelector, origin);

    if (widgetPosition) {
      dispatch('chatbot/updatePosition', {
        left: widgetPosition.x,
        top: widgetPosition.y,
        bottom: 0,
        right: 0,
      });
    }
  }

  // if the widget has unread messages open the chatbot conversation page
  const unreadMessagesCount = state.chatbot.conversation.unreadCount;
  if (expanded && unreadMessagesCount > 0) {
    const { conversationId, nodeId, lastMessageId } = state.chatbot.conversation;
    openChatbotPage({ nodeId }); // should add referral?
    markAsRead(conversationId, lastMessageId);
  }
  const unreadCount = state.chatbot.conversation?.unreadCount || 0;
  wixSupportChatSDKEmitUpdateWidget(expanded, unreadCount);
  dispatch('@events/widget/expanded', expanded);
  publishWidgetExpanded(expanded);
};

export const widgetCurrentUrlMiddleware = ({ currentUrl, state }: { currentUrl: string; state: State }) => {
  if (currentUrl === CHATBOT_PAGE_URL) {
    const { unreadCount, conversationId, lastMessageId } = state.chatbot.conversation;
    const expanded = state.widget.expanded;
    if (unreadCount > 0 && conversationId && lastMessageId && expanded) {
      markAsRead(conversationId, lastMessageId);
    }
  }
};
