import type { InitParams, ActiveChatSession, WidgetRuntimeState } from '@wix/wix-help-widget-common/types';
import { dispatch, getState, onStoreEvent } from '../store';
import { localizationService, InitWidgetService, updateWidgetSession, DealerOfferService } from '../services';
import { renderWidget } from '../render';
import {
  subscribeToPostMessage,
  subscribeToNavigation,
  subscribeToWidgetStateChange,
  subscribeToAppRender,
} from '../events/subscribe';
import {
  publishContext,
  publishNewDealerOfferParams,
  publishOrigParams,
  publishStartUrl,
} from '../widget/publishers';
import { widgetModel } from '../widgetModel';
import { WidgetSDKHandler } from '../events/WidgetSDKHandler';
import type { IWidget } from '../widget';
import { buildHelpWidgetStartUrl } from '../widget/startUrl';
import { WidgetRuntimeService } from '../services/WidgetRuntimeService';
import type { DealerOfferDetails } from '../dealer';
import type { InitWidgetResponse, UpdateWidgetSessionResponse } from '../types';

// Responsible for the help widget lifecycle when we are rendering the full help experience.
export class HelpWidget implements IWidget {
  private initWidgetService: InitWidgetService;
  private widgetRuntimeService: WidgetRuntimeService;
  private dealerOfferService: DealerOfferService;
  private updateWidgetSessionPromise: Promise<UpdateWidgetSessionResponse>;
  private iframeLoaded: boolean = false;

  constructor(initParams: InitParams) {
    if (!initParams || !initParams.origin) {
      throw new Error('Must pass an origin');
    }
    // setting the store with init params only once
    // we should find a better solution, if there is...
    dispatch('app/initParams', initParams);
    this.initWidgetService = new InitWidgetService();
    this.widgetRuntimeService = new WidgetRuntimeService();
    this.dealerOfferService = new DealerOfferService({ origin: initParams.origin });
  }

  private setWidgetIframeUrl(): void {
    const { initParams, chatbot } = getState();
    const iframeUrl = buildHelpWidgetStartUrl(initParams.origin, chatbot.chatbotActiveSession);
    dispatch('app/setWidgetIframeSrc', iframeUrl);
  }

  async initialize(): Promise<void> {
    await Promise.all([localizationService.init(widgetModel.getLocale()), this.initWidgetService.fetch()]);
    this.setWidgetIframeUrl();
  }

  async refetchInitialData(): Promise<void> {
    await this.initWidgetService.fetch();
  }

  registerSubscribers(): void {
    subscribeToAppRender();
    subscribeToNavigation(this.handleNavigation);
    subscribeToPostMessage(new WidgetSDKHandler(this));
    subscribeToWidgetStateChange((data) => {
      this.updateWidgetSessionPromise = updateWidgetSession(data);
    });
    this.subscribeToWidgetMount();
  }

  render(): void {
    renderWidget();
  }

  getRuntimeState(): Promise<WidgetRuntimeState | undefined> {
    return this.widgetRuntimeService.get();
  }

  getDealerOfferDetails(): Promise<DealerOfferDetails | undefined> {
    return this.dealerOfferService.get();
  }

  getChatbotActiveSession(): ActiveChatSession | undefined {
    return getState().chatbot.chatbotActiveSession;
  }

  refreshRuntimeState(): void {
    this.widgetRuntimeService.refresh();
  }

  private async refetchInitialWidgetDataAfterUpdateWidgetSession(): Promise<InitWidgetResponse | undefined> {
    try {
      await this.updateWidgetSessionPromise;
      return this.initWidgetService.fetch();
    } catch (error) {
      return this.initWidgetService.fetch();
    }
  }

  private handleNavigation = async (): Promise<void> => {
    dispatch('app/pageNavigate');

    await Promise.all([
      this.refetchInitialWidgetDataAfterUpdateWidgetSession(),
      this.iframeLoaded && this.dealerOfferService.fetch(),
    ]);

    await Promise.all([
      publishStartUrl(),
      this.iframeLoaded && publishNewDealerOfferParams(),
      publishContext(),
      publishOrigParams(),
    ]);

    this.render();
  };

  private subscribeToWidgetMount = (): void => {
    onStoreEvent('@events/widget/iframeSrcSet', () => {
      this.dealerOfferService.fetch();
    });

    // use iframeLoaded because in case if iframe is failed we will not fetch offer on each navigation(decreasing API calls)
    onStoreEvent('@events/widget/iframeLoaded', () => {
      this.iframeLoaded = true;
    });
  };
}
