import { Duplexer } from '@wix/duplexer-js';
import {
  DuplexerSubscriber,
  DuplexerEvent,
  DEFAULT_MESSAGE_READ_DELAY,
} from '@wix/wix-help-widget-common/duplexer';
import { WIDGET_REFRESH_STORAGE_KEY } from '@wix/wix-chatbot-common/browser';
import { dispatch, getState } from '../store';
import { widgetModel } from '../widgetModel';
import { wixSupportChatSDKEmitUpdateWidget } from '../components/common';
import { ChatAction } from './types';
import { shouldClearNotifications } from './notificationsHelper';
import { markAsRead } from '../api/messageApi';
import { publishSessionRefresh } from '../events/publish/widgetChannel';

// No need to implement logger for now.
const logger = {
  log: () => {},
};

export class ChatbotMessageSubscriber extends DuplexerSubscriber {
  constructor(
    protected readonly duplexer: Duplexer,
    protected readonly appDefId: string,
    private isTabActive: boolean,
  ) {
    super(duplexer, appDefId, logger);
    this.subscribe(DuplexerEvent.CHATBOT_STATE_UPDATE, this.processNotifications);
    this.subscribe(DuplexerEvent.MESSAGE_READ, this.onMessageRead);
    this.subscribe(DuplexerEvent.WIDGET_SESSION_CREATED, this.onWidgetSessionRefresh);
  }

  unsubscribe() {
    super.unsubscribe();
  }

  setTabActive(isActive: boolean) {
    this.isTabActive = isActive;
  }

  private onWidgetSessionRefresh = (): void => {
    // set by the tab where the user clicked on "refresh" button.
    // This way only other tabs which recive duplexer event will refresh the page.
    const isTabRefreshed = window.sessionStorage.getItem(WIDGET_REFRESH_STORAGE_KEY);
    if (isTabRefreshed) {
      window.sessionStorage.removeItem(WIDGET_REFRESH_STORAGE_KEY);
      return;
    }
    dispatch('@event/app/sessionExpired');
    publishSessionRefresh();
  };

  private processNotifications = async (data: ChatAction): Promise<void> => {
    const { ui, meta, startChatData, messages, unreadMessagesCount } = data;
    const conversationId = data.conversationId || data.startChatData?.conversationId;
    const { widget } = getState();
    const { expanded } = widget;
    const isNewConversation = !!startChatData;
    const lastMessageId = messages[messages.length - 1]?.id || '';
    if (shouldClearNotifications(widget, isNewConversation, this.isTabActive)) {
      wixSupportChatSDKEmitUpdateWidget(expanded, 0);
      if (conversationId && lastMessageId) {
        markAsRead(conversationId, lastMessageId);
      }
      return;
    }

    dispatch('chatbot/conversation', {
      unreadCount: unreadMessagesCount,
      nodeId: ui?.nodeId ?? '',
      lang: meta?.locale ?? '',
      conversationId: (conversationId || startChatData?.conversationId) ?? '',
      lastMessageId,
    });
    // TODO: Remove logic and just send duplexer of message_unread
    // This makes sure that the pimple and the ping notification will be displayed at the same time or cleaned up
    // if the user read the message
    await this.raceMessageReadUnread(expanded, unreadMessagesCount);
  };

  private async raceMessageReadUnread(
    expanded: boolean,
    unreadMessagesCount: number,
  ): Promise<boolean | void> {
    const config = widgetModel.getConfig();
    const waitTime = config?.duplexer.messageReadDelay ?? DEFAULT_MESSAGE_READ_DELAY; // sec

    return Promise.race([
      new Promise<boolean>((resolve) => setTimeout(() => resolve(false), waitTime * 1000)),
      new Promise<boolean>((resolve) => {
        // If msg was read, don't show the pimple
        this.once(DuplexerEvent.MESSAGE_READ, () => resolve(true));
      }),
    ]).then((messageRead) => {
      wixSupportChatSDKEmitUpdateWidget(expanded, messageRead ? 0 : unreadMessagesCount);
    });
  }

  private onMessageRead = (): void => {
    const {
      widget: { expanded },
    } = getState();
    dispatch('chatbot/unreadCount', 0);
    wixSupportChatSDKEmitUpdateWidget(expanded, 0);
  };
}
