import { StoreonModule } from 'storeon';
import type { ActiveChatSession, WidgetData } from '@wix/wix-help-widget-common/types';
import { State, Events, ItemPosition } from '..';
import { SessionService } from '../../services';
import { DealerOfferDetails } from '../../dealer';

export type ChatbotConversationData = {
  unreadCount: number;
  nodeId: string;
  lang: string;
  conversationId: string;
  lastMessageId?: string;
};

type ChatbotOrigParams = {
  origPageName: string;
  origPlatform: string;
};

export type ChatbotState = {
  chatbot: {
    chatbotActiveSession?: ActiveChatSession;
    isChatbotSessionActive: boolean;
    conversation: ChatbotConversationData;
    position?: ItemPosition;
    forceStartChat: boolean;
    dealerOffer?: DealerOfferDetails;
  } & ChatbotOrigParams;
};

export type ChatbotEvents = {
  'chatbot/setActiveSession'?: ActiveChatSession;
  'chatbot/setDealerOffer': DealerOfferDetails;
  'chatbot/conversation': ChatbotConversationData;
  'chatbot/unreadCount': number;
  'chatbot/updatePosition': ItemPosition;
  'chatbot/forceStartChat': boolean;
  // Responsible for updating notifications counter in anchor and sending BI events when unread messages appear.
  '@events/chatbot/unreadCount': number;
};

export const chatbotModule: StoreonModule<State, Events> = ({ on, dispatch }) => {
  on('@init', () => ({
    chatbot: {
      forceStartChat: false,
      isChatbotSessionActive: false,
      conversation: {
        unreadCount: 0,
        nodeId: '',
        lang: '',
        conversationId: '',
        lastMessageId: '',
      },
      origPageName: '',
      origPlatform: '',
    },
  }));

  on('chatbot/setActiveSession', (state, action) => {
    const { chatbot } = state;
    const isChatbotSessionActive = SessionService.isSessionActive(action);
    const unreadCount = action?.unreadMessagesCount || 0;
    const conversationId = action?.conversationId || '';
    const nodeId = action?.nodeId || '';
    const lastMessageId = action?.lastMessageId || '';
    const overrideOrigParams = !isChatbotSessionActive || !chatbot.origPageName || !chatbot.origPlatform;
    return {
      chatbot: {
        ...chatbot,
        conversation: {
          ...chatbot.conversation,
          conversationId,
          nodeId,
          lastMessageId,
          unreadCount,
        },
        chatbotActiveSession: action,
        isChatbotSessionActive,
        ...(overrideOrigParams && createOrigParams()),
      },
    };
  });

  on('chatbot/setDealerOffer', ({ chatbot }, dealerOffer) => ({
    chatbot: {
      ...chatbot,
      dealerOffer,
    },
  }));

  on('chatbot/forceStartChat', ({ chatbot }, forceStartChat) => ({
    chatbot: {
      ...chatbot,
      forceStartChat,
    },
  }));

  on('chatbot/unreadCount', ({ chatbot }, unreadCount) => {
    dispatch('@events/chatbot/unreadCount', unreadCount);
    return {
      chatbot: {
        ...chatbot,
        conversation: {
          ...chatbot.conversation,
          unreadCount,
        },
      },
    };
  });

  /**
   * Triggered when new messages arrive from duplexer.
   * Update the chatbot reducer.
   * Will also update session (dataCapsule) only when new unread messages sent to the same conversation.
   */
  on('chatbot/conversation', ({ chatbot, widget }, action) => {
    const newState = {
      chatbot: {
        ...chatbot,
        ...createOrigParams(),
        isChatbotSessionActive: true, // TODO: is it ok?
        conversation: action,
      },
    };

    const isNewConversation = action.conversationId
      ? chatbot.conversation.conversationId !== action.conversationId
      : false;
    if (isNewConversation || widget.expanded) {
      return newState;
    }
    const newUnreadCount = chatbot.conversation.unreadCount + action.unreadCount;
    newState.chatbot.conversation.unreadCount = newUnreadCount;
    if (action.unreadCount === 0) {
      return newState;
    }

    dispatch('@events/chatbot/unreadCount', newUnreadCount);
    return {
      ...newState,
      __dataCapsule: Date.now(),
    };
  });

  on('chatbot/updatePosition', ({ chatbot }, position) => ({
    chatbot: {
      ...chatbot,
      position,
    },
    __dataCapsule: Date.now(),
  }));

  on('app/setInitialData', (state, { widgetSession, activeChatSession }) => {
    const {
      chatbot,
      initParams: { isInvokedFromHeader },
    } = state;
    const isChatbotSessionActive = SessionService.isSessionActive(activeChatSession);
    const overrideOrigParams = !isChatbotSessionActive || !chatbot.origPageName || !chatbot.origPlatform;
    return {
      chatbot: {
        ...state.chatbot,
        ...(!isInvokedFromHeader && {
          position: getPositionFromWidgetData(state, widgetSession?.widgetData),
        }),
        conversation: {
          ...state.chatbot.conversation,
          conversationId: activeChatSession?.conversationId || '',
          nodeId: activeChatSession?.nodeId || '',
          lastMessageId: activeChatSession?.lastMessageId || '',
          unreadCount: activeChatSession?.unreadMessagesCount || 0,
          lang: activeChatSession?.locale || state.chatbot.conversation.lang,
        },
        ...(overrideOrigParams && createOrigParams()),
        isChatbotSessionActive,
        chatbotActiveSession: activeChatSession,
      },
    };
  });
};

function createOrigParams(): ChatbotOrigParams {
  return {
    origPageName: document.title,
    origPlatform: window.location.href,
  };
}

function getPositionFromWidgetData(
  oldState: Readonly<State>,
  widgetData?: Readonly<WidgetData>,
): ItemPosition | undefined {
  if (!widgetData || !widgetData.chatbot) {
    return undefined;
  }
  const {
    chatbot: { position, specificOriginPositions },
  } = widgetData;
  const originPosition = specificOriginPositions?.find(({ origin }) => origin === oldState.initParams.origin);

  return originPosition || position || oldState.chatbot.position;
}
