import {
  WidgetInternalSDKMethods,
  Context,
  InitialParams,
  OrigParams,
} from '@wix/wix-chatbot-common/browser';
import { WidgetRuntimeState } from '@wix/wix-help-widget-common/types';

import type { IWidget } from '../widget';
import { widgetModel } from '../widgetModel';
import { whitelistUrlParams } from '../widget/startUrl';
import { getOfferParams } from '../dealer';
import { fedopsLogger } from '../fedops';
import { dispatch, getState } from '../store';
import { assertExists } from '../utils';
import { getContext } from '../utils/context';

export class WidgetSDKHandler implements WidgetInternalSDKMethods {
  constructor(private readonly widget: IWidget) {}
  clientAppLoaded(): void {
    fedopsLogger.interactionEnded('client-app-loaded');
    dispatch('@events/app/setClientAppLoaded');
  }

  async refreshOnUserLogin(): Promise<void> {
    await Promise.all([widgetModel.refetchModel(), this.widget.refetchInitialData()]);
  }

  /**
   * Gets the object with params for the start chat request(like nodeId, referral, versionId, collectionsData etc.).
   * order of where we get params is:
   * 1. URL. if url has start chat params(like nodeId, versionId etc.) we use params from url.
   * 2. Session. if chatbot session is active we use params from this session;
   * 3. DealerOffer. If 1 and 2 are falsy we get link from DealerOffer and use params from this link.
   *
   * @returns {Promise<Record<string, string>>} - object with params for the start chat request(like nodeId, referral, collectionsData etc.).
   */
  async getStartChatParams() {
    const searchParams = new URLSearchParams(window.location.search);

    // if there's at least 1 param from the whitelist, we treat it as deep link
    if (whitelistUrlParams.some((key) => searchParams.has(key))) {
      return Object.fromEntries(searchParams);
    }

    const activeSession = this.widget.getChatbotActiveSession();

    if (activeSession?.startUrl) {
      const startUrlParams = new URLSearchParams(activeSession?.startUrl);
      return Object.fromEntries(startUrlParams);
    }

    const dealerOffer = await this.widget.getDealerOfferDetails();
    return getOfferParams(dealerOffer);
  }

  getContext(): Promise<Context> {
    return getContext();
  }

  getOrigParams(): OrigParams {
    const {
      chatbot: { origPageName, origPlatform },
    } = getState();

    return {
      origPlatform,
      origPageName,
    };
  }
  getInitialParams(): InitialParams {
    const { initParams } = getState();
    return {
      authToken: initParams.authorizationToken || '',
    };
  }

  getRuntimeState(): Promise<WidgetRuntimeState | undefined> {
    assertExists(
      this.widget,
      'Widget is not initialized yet (or disabled). Call init before getRuntimeState',
    );
    return this.widget.getRuntimeState();
  }

  refreshRuntimeState(): void {
    assertExists(
      this.widget,
      'Widget is not initialized yet (or disabled). Call init before refreshRuntimeState',
    );
    return this.widget.refreshRuntimeState();
  }
}
