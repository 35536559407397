import React, { useEffect } from 'react';

import { StoreContext } from 'storeon/react';
import { getState, store } from '../store/index';

import { WidgetContainer } from '../components/WidgetContainer';
import { WidgetButton } from '../components/WidgetButton/WidgetButton';
import { WidgetWrapper } from '../components/WidgetWrapper';
import { biWidgetLoaded } from '../bi';
import { WidgetTooltip } from '../components/WidgetTooltip';

import { useUnreadMessagesSubscriber } from '../customHooks/useUnreadMessagesSubscriber';
import { useChatbotMessagesSubscriber } from '../customHooks/useChatbotMessagesSubscriber';
import { useMarkAsReadOnTabChange } from '../customHooks/useMarkAsReadOnTabChange';
import { WixDesignSystemProvider } from '@wix/design-system';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { localizationService } from '../services';

export const Root: React.FC = () => {
  const {
    initParams: { placeholder, isInvokedFromHeader },
    widgetIframeSrc,
  } = getState();

  useChatbotMessagesSubscriber();

  useEffect(() => {
    biWidgetLoaded();
  }, [widgetIframeSrc]);

  useUnreadMessagesSubscriber();
  useMarkAsReadOnTabChange();

  const showWidgetButton = !isInvokedFromHeader && !placeholder?.element;
  const showWidgetTooltip = isInvokedFromHeader;

  const i18n = localizationService.i18n;

  return (
    <StoreContext.Provider value={store}>
      <WidgetWrapper>
        {showWidgetButton && <WidgetButton />}
        <WixDesignSystemProvider features={{ newColorsBranding: true }} style={{ width: '100%' }}>
          {i18n ? (
            <I18nextProvider i18n={i18n}>
              {showWidgetTooltip && <WidgetTooltip />}
              <WidgetContainer />
            </I18nextProvider>
          ) : null}
        </WixDesignSystemProvider>
      </WidgetWrapper>
    </StoreContext.Provider>
  );
};
