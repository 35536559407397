import { useTranslation } from '@wix/wix-i18n-config';
import React, { forwardRef, useCallback } from 'react';
import { Box, PopoverProps, Tooltip } from '@wix/design-system';
import { getState } from '../../store';

import { widgetModel } from '../../widgetModel';
import { WIDGET_DRAG_ICON, WIDGET_DRAG_ICON_TOOLTIP } from '../../constants';
import { biTooltipClose, biTooltipLoad } from '../../bi';
import { DragIconSvg } from './DragIconSvg';

type DragIconProps = Readonly<{
  isDetached: boolean | undefined;
}>;

const dragIconTooltipMoveBy: PopoverProps['moveBy'] = { y: 6 };
const biTooltipName = 'widget-drag-icon-tooltip';

export const DragIcon = forwardRef<HTMLDivElement, DragIconProps>(({ isDetached }, ref) => {
  const { t } = useTranslation();
  const { initParams } = getState();
  const enableDragIconTooltip = widgetModel
    .getExperiments()
    .enabled('specs.helpwidget.enableDragIconTooltip');
  // tooltip only appears before the user drags the widget;
  const tooltipDisabled = !enableDragIconTooltip || !initParams.isInvokedFromHeader || isDetached;

  const onShown = useCallback(() => {
    biTooltipLoad({
      title: biTooltipName,
      content: t('drag_icon_tooltip_content'),
    });
  }, [t]);

  const onHide = useCallback(() => {
    biTooltipClose({
      title: biTooltipName,
      content: t('drag_icon_tooltip_content'),
    });
  }, [t]);

  return (
    <Box position="absolute" width="100%" align="center">
      <Tooltip
        content={t('drag_icon_tooltip_content')}
        size="small"
        maxWidth="100%"
        moveBy={dragIconTooltipMoveBy}
        disabled={tooltipDisabled}
        onShow={onShown}
        onHide={onHide}
        dataHook={WIDGET_DRAG_ICON_TOOLTIP}
      >
        <Box ref={ref} padding="3px 24px" cursor="move" color="D40" dataHook={WIDGET_DRAG_ICON}>
          <DragIconSvg />
        </Box>
      </Tooltip>
    </Box>
  );
});
