import { NavigationParams, NavigationByUrl } from '@wix/wix-chatbot-common/browser';

export const isUrlNavigationParameters = (params: NavigationParams): params is NavigationByUrl =>
  'url' in params;

export const navigateTo = (params: NavigationParams): void => {
  try {
    if (!isUrlNavigationParameters(params)) {
      return;
    }

    if (params.openInNewTab) {
      window.open(params.url, '_blank');
    }

    window.location.href = params.url;
  } catch (error) {}
};
