import type { ActiveChatSession, InitParams, WidgetRuntimeState } from '@wix/wix-help-widget-common/types';

import { dispatch, getState } from '../store';
import { localizationService, InitWidgetService, updateWidgetSession, DealerOfferService } from '../services';
import { renderWidget } from '../render';
import {
  subscribeToPostMessage,
  subscribeToNavigation,
  subscribeToWidgetStateChange,
  subscribeToAppRender,
} from '../events/subscribe';
import {
  publishContext,
  publishNewDealerOfferParams,
  publishOrigParams,
  publishStartUrl,
} from '../widget/publishers';
import { widgetModel } from '../widgetModel';
import { WidgetSDKHandler } from '../events/WidgetSDKHandler';
import type { IWidget } from '../widget';
import { getStartUrl } from '../widget/startUrl';
import type { DealerOfferDetails } from '../dealer';
import { WidgetRuntimeService } from '../services/WidgetRuntimeService';
import type { InitWidgetResponse, UpdateWidgetSessionResponse } from '../types';

// Responsible for the chatbot widget lifecycle when we are rendering only chatbot page experience.
export class ChatbotWidget implements IWidget {
  private initWidgetService: InitWidgetService;
  private widgetRuntimeService: WidgetRuntimeService;
  private dealerOfferService: DealerOfferService;
  private updateWidgetSessionPromise: Promise<UpdateWidgetSessionResponse>;

  constructor(initParams: InitParams) {
    if (!initParams || !initParams.origin) {
      throw new Error('Must pass an origin');
    }
    // setting the store with init params only once
    // we should find a better solution, if there is...
    dispatch('app/initParams', initParams);
    this.initWidgetService = new InitWidgetService();
    this.widgetRuntimeService = new WidgetRuntimeService();
    this.dealerOfferService = new DealerOfferService({ origin: initParams.origin });
  }

  async initialize(): Promise<void> {
    const [dealerOffer, initialWidgetData] = await Promise.all([
      this.dealerOfferService.fetch(),
      this.initWidgetService.fetch(),
      localizationService.init(widgetModel.getLocale()),
    ]);
    this.setWidgetIframeUrl(initialWidgetData?.activeChatSession, dealerOffer);
  }

  async refetchInitialData(): Promise<void> {
    await this.initWidgetService.fetch();
  }

  registerSubscribers(): void {
    subscribeToAppRender();
    subscribeToNavigation(this.handleNavigation);
    subscribeToPostMessage(new WidgetSDKHandler(this));
    subscribeToWidgetStateChange((data) => {
      this.updateWidgetSessionPromise = updateWidgetSession(data);
    });
  }

  render(): void {
    renderWidget();
  }

  getRuntimeState(): Promise<WidgetRuntimeState | undefined> {
    return this.widgetRuntimeService.get();
  }

  getDealerOfferDetails(): Promise<DealerOfferDetails | undefined> {
    return this.dealerOfferService.get();
  }

  getChatbotActiveSession(): ActiveChatSession | undefined {
    return getState().chatbot.chatbotActiveSession;
  }

  refreshRuntimeState(): void {
    this.widgetRuntimeService.refresh();
  }

  private async refetchInitialWidgetDataAfterUpdateWidgetSession(): Promise<InitWidgetResponse | undefined> {
    try {
      await this.updateWidgetSessionPromise;
      return this.initWidgetService.fetch();
    } catch (error) {
      return this.initWidgetService.fetch();
    }
  }

  private handleNavigation = async (): Promise<void> => {
    dispatch('app/pageNavigate');
    const [initWidgetData, dealerOffer] = await Promise.all([
      this.refetchInitialWidgetDataAfterUpdateWidgetSession(),
      this.dealerOfferService.fetch(),
    ]);
    await Promise.all([
      publishStartUrl(),
      publishNewDealerOfferParams(),
      publishContext(),
      publishOrigParams(),
    ]);
    this.setWidgetIframeUrl(initWidgetData?.activeChatSession, dealerOffer);
    this.render();
  };

  private setWidgetIframeUrl(activeChatSession?: ActiveChatSession, dealerOffer?: DealerOfferDetails): void {
    const { initParams } = getState();
    const iframeUrl = getStartUrl(initParams, activeChatSession, dealerOffer);
    dispatch('app/setWidgetIframeSrc', iframeUrl);
  }
}
